import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    isNumeric(str: any) {
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    constructor() {}
}
