<div class="pc-add-registration-wrap">
    <div class="reg-info">
        <div class="close-link-wrap"><a [routerLink]="['/my-registrations']" class="close-link">{{'close'| translate}}</a></div>
        <div class="registration-info">
            <div class="registration-user-info">
                <img class="registration-user-info-picture" src="{{activeRegistration?.picture}}">
                <div class="registration-user-info-fullname"><span>{{activeRegistration?.firstname}} {{activeRegistration?.lastname}}</span></div>
            </div>
            <div class="registration-info-icon">
                <a class="d-none-mobile" [routerLink]="['edit']" ><i class="icon icon-edit filter-blue"></i></a>
                <a class="d-flex-mobile" [routerLink]="['/add-registration']" ><i class="icon icon-edit filter-blue"></i></a>
                <button (click)="openConfirmDeleteModal(activeRegistration?.id)" class="icon-delete-wrap"><i class="icon icon-delete"></i></button>
            </div>
        </div>
        <div class="display-content">
            <div class="display-label">{{'myregistrations_period_title' | translate}}</div>
            <div class="display-value">{{activeRegistration?.startDate | formatTs: 'DD-MM-YYYY'}} {{'myregistrations_period_length_text' | translate}} {{activeRegistration?.endDate |  formatTs: 'DD-MM-YYYY'}}</div>
        </div>
        <div class="display-content">
            <div class="display-label">{{'myregistrations_th_km' | translate}}</div>
            <div class="display-value">{{activeRegistration?.km | thousandSeparator}} km</div>
        </div>
        <div class="reason-client-wrap">
            <div class="display-content">
                <div class="display-label reg-reason">{{'myregistrations_th_reden' | translate}}</div>
                <div class="display-value">
                    {{'reason_' + activeRegistration?.reason | translate}}</div>
            </div>
            <div class="display-content" *ngIf="activeRegistration?.client">
                <div class="display-label">{{'myregistrations_client_label' | translate}}</div>
                <div class="display-value">{{activeRegistration?.client}}</div>
            </div>
        </div>
        <div class="edit-added-ts-wrap">
            <div>{{'myregistrations_added_text'|translate}} {{activeRegistration?.createTS | formatTs: 'DD-MM-YYYY'}} {{'myregistrations_added_until_text'|translate}} {{activeRegistration?.createTS | formatTs: 'HH:mm'}} {{'myregistrations_added_by_text'|translate}} {{activeRegistration?.createUserName}}</div>
            <div>{{'myregistrations_edited_text'|translate}} {{activeRegistration?.editTS | formatTs: 'DD-MM-YYYY'}} {{'myregistrations_edited_until_text'|translate}} {{activeRegistration?.editTS | formatTs: 'HH:mm'}} {{'myregistrations_edited_by_text'|translate}} {{activeRegistration?.editUserName}}</div>
        </div>
    </div>
</div>