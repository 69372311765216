<div class="delete-pop-up">
    <h3 class="delete-sure-title">
        {{'my_registrations_delete_title' | translate}}
    </h3>
    <div class="delete-sure-text">
        {{'my_registrations_delete_text' | translate}}
    </div>
    <div class="seperator-line">
    </div>
    <div class="delete-sure-foot">
        <app-button buttonWidth="" (clickButton)="canceled.emit()" [type]="'button-no-delete--red'" [text]="'my_registrations_delete_button_no' | translate"></app-button>
        <app-button [disabled]="deleting" buttonWidth=""  (clickButton)="confirmed.emit()" [type]="'button-delete--red'" [text]="'my_registrations_delete_button_yes' | translate"></app-button>
    </div>
</div>