import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DefaultService } from '../utils/api';
import { TranslatePipe } from '../utils/pipes';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationsService {
    constructor(
        private defaultService: DefaultService,
        private notificationService: NotificationService,
        private translatePipe: TranslatePipe,
        private router: Router
    ) {}

    getRegistrations(
        startRow: number,
        RPP?: number,
        orderBy?: string[],
        userId?: string,
        registrationId?: string
    ): any {
        return this.defaultService.registrationGetAll(startRow, RPP, orderBy, userId, registrationId);
    }

    deleteRegistrations(deleteModal: any, deleteId: string) {
        this.defaultService.registrationRemove(deleteId).subscribe((result) => {
            this.notificationService.sendMessage(
                this.translatePipe.transform('registration_deleted_toastr'),
                'success'
            );
            this.router.navigate(['.'], { queryParams: { action: 'update' } });
            deleteModal.dismiss();
        });
    }

    updateRegistrations(FORM: any) {
        return this.defaultService.registrationUpdateOrInsert(FORM);
    }
}
