import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor() {}

    public getDayLabels() {
        let weekdayArray: string[] = [];
        for (let i = 0; i <= 6; i++) {
            weekdayArray.push(moment.weekdays(i).charAt(0).toUpperCase() + moment.weekdays(i).slice(1).substring(0, 1));
        }
        return {
            su: weekdayArray[0],
            mo: weekdayArray[1],
            tu: weekdayArray[2],
            we: weekdayArray[3],
            th: weekdayArray[4],
            fr: weekdayArray[5],
            sa: weekdayArray[6]
        };
    }
}
