import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
    @Input() RPP?: number = 25;
    @Input() startRow?: number = 0;
    @Input() totalRows?: number = null;
    @Input() currentPage: number = 1;
    @Output() startRowChange = new EventEmitter();

    pages: any[] = [];

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this.currentPage = Math.ceil(this.startRow / this.RPP) + 1;
        this.setPages();
    }

    setPages() {
        const amount = this.totalRows / this.RPP;
        let pages;
        if (amount < 1) {
            pages = new Array(1);
        } else {
            pages = new Array(Math.ceil(amount));
        }
        for (var i = 0; i < pages.length; i++) {
            pages[i] = i + 1;
        }
        this.pages = pages;
    }

    truncate(i: number) {
        if (this.pages.length <= 5) {
            // show 5 pages always
            return true;
        } else if (this.currentPage <= 3) {
            // more than 5 pages
            if (i <= 5) {
                return true;
            }
            return false;
        } else if (this.currentPage >= this.pages.length - 2) {
            if (i >= this.pages.length - 4) {
                return true;
            } else return false;
        } else {
            //longer than 7 => truncate all but curr & +/- 1
            if (i <= this.currentPage + 1 && i >= this.currentPage - 1) {
                return true;
            } else return false;
        }
    }

    showFill(first: boolean) {
        if (first) {
            if (this.currentPage > 3 && this.pages.length > 5) {
                return true;
            }
            return false;
        } else {
            if (this.currentPage < this.pages.length - 2 && this.pages.length > 5) {
                return true;
            }
            return false;
        }
    }

    changePage(page: any) {
        if (!page) return;
        let res = null;
        switch (page) {
            case 'prev':
                res = this.startRow - this.RPP;
                break;
            case 'next':
                res = this.startRow + this.RPP;
                break;
            default:
                res = page * this.RPP - this.RPP;
                break;
        }
        this.startRowChange.emit(res);
    }
}
