import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel, IMyRangeDateSelection } from 'angular-mydatepicker';
import * as moment from 'moment';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
    locale: string = this.translatorService.getLocaleDatePicker();
    today: any;
    @Input() labelText: any;
    localModel: IMyDateModel;
    load: boolean = false;
    @Input() endDate: Date;
    @Input() startDate: Date;
    @Output() modelChange: EventEmitter<any> = new EventEmitter();

    myDatePickerOptions: IAngularMyDpOptions = {
        monthSelector: false,
        yearSelector: false,
        inline: true,
        dateRange: true,
        dayLabels: this.resourceService.getDayLabels(),
        markCurrentDay: false,
        selectorWidth: '100%',
        stylesData: {
            styles: `
                .myDpIconLeftArrow,
                .myDpIconRightArrow {
                    display: flex;
                    align-items: center;
                }
                .myDpHeaderBtn{
                    color: #333;
                }
            `
        }
    };
    constructor(private resourceService: ResourceService, private translatorService: TranslatorService) {}

    ngOnInit(): void {
        this.today = moment();
        this.myDatePickerOptions.disableSince = {
            year: this.today.year(),
            month: this.today.month() + 1,
            day: this.today.date() + 1
        };
        if (this.startDate && this.endDate) {
            this.localModel = {
                isRange: true,
                dateRange: {
                    beginDate: {
                        year: Number(moment(this.startDate).format('yyyy')),
                        month: Number(moment(this.startDate).format('M')),
                        day: Number(moment(this.startDate).format('D'))
                    },
                    endDate: {
                        year: Number(moment(this.endDate).format('yyyy')),
                        month: Number(moment(this.endDate).format('M')),
                        day: Number(moment(this.endDate).format('D'))
                    }
                }
            };
        }
        setTimeout(() => {
            this.load = true;
        }, 100);
    }

    onDateRangeSelection($event) {
        // this.localModel = {
        //     dateRange: {
        //         beginDate: {
        //             year: $event.date.year,
        //             month: $event.date.month,
        //             day: $event.date.day
        //         },
        //         endDate: {
        //             year: $event.date.year,
        //             month: $event.date.month,
        //             day: $event.date.day
        //         }
        //     }
        // };
        this.modelChange.emit({
            start_date: `${$event.date.year}-${moment($event.date.month, 'M').format('MM')}-${moment(
                $event.date.day,
                'D'
            ).format('DD')}`,
            end_date: `${$event.date.year}-${moment($event.date.month, 'M').format('MM')}-${moment(
                $event.date.day,
                'D'
            ).format('DD')}`
        });
    }

    onDateChanged($event) {
        // this.localModel = {
        //     dateRange: {
        //         beginDate: {
        //             year: $event.dateRange.beginDate.year,
        //             month: $event.dateRange.beginDate.month,
        //             day: $event.dateRange.beginDate.day
        //         },
        //         endDate: {
        //             year: $event.dateRange.endDate.year,
        //             month: $event.dateRange.endDate.month,
        //             day: $event.dateRange.endDate.day
        //         }
        //     }
        // };
        this.modelChange.emit({
            start_date: `${$event.dateRange.beginDate.year}-${moment($event.dateRange.beginDate.month, 'M').format(
                'MM'
            )}-${moment($event.dateRange.beginDate.day, 'D').format('DD')}`,
            end_date: `${$event.dateRange.endDate.year}-${moment($event.dateRange.endDate.month, 'M').format(
                'MM'
            )}-${moment($event.dateRange.endDate.day, 'D').format('DD')}`
        });
    }
}
