import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);
    constructor(private DefaultService: DefaultService) {}

    public login() {
        return this.DefaultService.userLogin().pipe(tap((next) => {}));
        // return Observable.of(null).pipe(tap((next) => {}));
    }

    public fetchUser(): any {
        return this.DefaultService.userMe().pipe(
            tap((next) => {
                this.user$.next(next);
                // this.user$.next({ ...next, roles: [] });
            })
        );
    }

    public logout() {
        // window.location.href = this.config.loginRedirectUrl;
        this.DefaultService.userLogout().subscribe((next) => {
            window.location.href = '/';
        });
    }

    public getUserValue() {
        return this.user$.getValue();
    }

    public auth(): any {
        window.location.href = '/api/user/login';
    }
}
