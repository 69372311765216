<div class="datepicker-disabled" [class.datepicker-enabled]="load">
<!-- <div> -->
    <div class="date-picker-title">
        {{labelText}}
    </div>
    <div>
        <input type="hidden" angular-mydatepicker [locale]="locale" [options]="myDatePickerOptions" 
        [(ngModel)]="localModel" (dateChanged)="onDateChanged($event)" (rangeDateSelection)="onDateRangeSelection($event)"/>
    </div>

</div>
