import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    user: any;
    constructor(private authService: AuthenticationService) {}

    ngOnInit(): void {
        this.user = this.authService.getUserValue();
    }

    logout() {
        this.authService.logout();
    }
}
