
<div class="page container">


        <!-- spinner -->
        <ng-container *ngIf="!ready">
            <div class="spinner-wrap">
                <app-spinner></app-spinner>
            </div>
        </ng-container>

        <!-- ready -->
        <div class="page-ready" *ngIf="ready">
            <ng-container *ngIf="!registrations?.length">
                <div class="empty-state-wrap">
                    <img class="no-reservation-image" src="/assets/img/misc/empty-state.svg" [alt]="'myregistrations_no_registrations' | translate" />
                    <div class="no-reservation-title">{{'myregistrations_emptystate_title' | translate}}</div>
                    <div class="add-reservation-text">{{'myregistrations_emptystate_descr' | translate}}</div>
                </div>
            </ng-container>
        
            <div class="registrations-computer-wrap" *ngIf="registrations?.length">
                <div class="registrations-wrap flex-grow-1">
                    <div class="my-registrations-title">
                        {{'myregistrations_title' | translate}}
                    </div>
                    <div class="table-wrap" [class.table-wrap--clickable]="isAdmin">
                        <table class="app-table">
                            <tr>
                                <th [style.width]="isAdmin? '23%' : '31%'"><div>{{'myregistrations_th_date' | translate}}</div></th>
                                <th [style.width]="isAdmin? '23%' : 0" *ngIf="isAdmin"><div>{{'myregistrations_th_driver' | translate}}</div></th>
                                <th [style.width]="isAdmin? '19%' : '34%'"><div>{{'myregistrations_th_km' | translate}}</div></th>
                                <th colspan="2" [style.width]="'35%'"><div>{{'myregistrations_th_reden' | translate}}</div></th>
                            </tr>
                            <!-- <tr *ngFor="let reg of registrations" [class.reg-active]="reg == specificRegistration && router.url !== '/my-registrations'" (click)="showRegistration(reg)"> -->
                            <tr *ngFor="let reg of registrations" (click)="isAdmin ? activeId = reg.id : ''">
                                <td [class.saving]="saving" [style.width]="isAdmin? '23%' : '31%'" [class.reg-active]="activeId === reg.id && router.url !== '/my-registrations'"><a class="pad" *ngIf="isAdmin" [routerLink]="[reg.id]" queryParamsHandling="preserve">{{reg.startDate | formatTs: 'DD-MM-YYYY'}}</a><div *ngIf="!isAdmin" class="pad">{{reg.startDate | formatTs: 'DD-MM-YYYY'}}</div></td>
                                <td [class.saving]="saving" [style.width]="isAdmin? '23%' : 0" [class.reg-active]="activeId === reg.id && router.url !== '/my-registrations'" *ngIf="isAdmin"><a class="pad"  [routerLink]="[reg.id]" queryParamsHandling="preserve">{{reg.firstname}}</a></td>
                                <td [class.saving]="saving" [style.width]="isAdmin? '19%' : '34%'" [class.reg-active]="activeId === reg.id && router.url !== '/my-registrations'"><a class="pad" *ngIf="isAdmin" [routerLink]="[reg.id]" queryParamsHandling="preserve">{{reg.km | thousandSeparator}} km</a><div *ngIf="!isAdmin" class="pad">{{reg.km | thousandSeparator}} km</div></td>
                                <td [class.saving]="saving" [style.width]="isAdmin? '32%' : '35%'" [class.reg-active]="activeId === reg.id && router.url !== '/my-registrations'">
                                    <a class="pad" *ngIf="isAdmin" [routerLink]="[reg.id]" queryParamsHandling="preserve">
                                        <div class="semicolon-flex">
                                            {{'reason_' + reg.reason | translate}}
                                            <div *ngIf="reg.reason == 'client'">
                                                :
                                            </div>

                                        </div>
                                        <div *ngIf="reg.reason == 'client'">
                                            {{reg.client}}
                                        </div>
                                    </a>
                                    <div *ngIf="!isAdmin" class="pad">
                                        <div class="semicolon-flex">
                                            {{'reason_' + reg.reason | translate}}
                                            <div *ngIf="reg.reason == 'client'">
                                                :
                                            </div>

                                        </div>
                                        <div *ngIf="reg.reason == 'client'">
                                            {{reg.client}}
                                        </div>
                                    </div>
                                </td>
                                <td [class.saving]="saving" class="fifth-column" [style.width]="isAdmin? '3%' : 0" [class.reg-active]="activeId === reg.id && router.url !== '/my-registrations'" *ngIf="isAdmin">
                                    <button (click)="$event.stopPropagation()" class="more-options-button" [ngbPopover]="popContent"
                                    placement="bottom-end">
                                        <i class="icon icon-adminOptions"></i>
                                    </button>
                                    <ng-template #popContent>
                                        <div class="simple-popover">
                                            <a [routerLink]="['/add-registration', reg.id]" class="button-wrap d-flex-mobile edit">
                                                <i class="icon icon-edit"></i>
                                                <span>{{'misc_edit' | translate}}</span>
                                            </a>
                                            <a [routerLink]="['/my-registrations', reg.id, 'edit']" class="button-wrap d-none-mobile edit">
                                                <i class="icon icon-edit"></i>
                                                <span>{{'misc_edit' | translate}}</span>
                                            </a>
                                            <button (click)="openConfirmDeleteModal(reg.id)" class="button-wrap delete">
                                                <i class="icon icon-delete"></i>
                                                <span>{{'misc_delete' | translate}}</span>
                                            </button>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="pagination">
                        <app-pagination [RPP]="RPP"
                        [currentPage]="currentPage"
                        [startRow]="startRow"
                        [totalRows]="totalRows"
                        (startRowChange)="startRowChange($event)"></app-pagination>
                    </div>
                </div>
            </div>
            <div class="sticky-bottom">
                <div class="app-button-wrap" [class.app-button-wrap-none]="registrations?.length">
                    <app-button buttonWidth="" [link]="['/add-registration']" [type]="'button--green'" [text]="'myregistrations_btn_add' | translate" [imageFirst]="true" [icon]="'plus'"></app-button>
                </div>
            </div>
        </div>
    <div class="router-outlet-wrap" [class.router-outlet-wrap--full-width]="mobileFullWidth && isAdmin" *ngIf="registrations?.length">
        <router-outlet></router-outlet>
    </div>
</div>