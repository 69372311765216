<ng-container>
    <div class="client-input-wrap">
        <span class="customer-span">
            {{label}}
        </span>
        <input class="field-input"  [class.input-error]="errorMessage" (keyup)="changeNameKeyDown()" [(ngModel)]="clientName" [placeholder]="placeholder" />
        <div class="input-error-message" *ngIf="errorMessage">
            {{errorMessage}}
        <!-- {{'completenewregistrations_customer_input_error' | translate}} -->
        </div>
    </div>
</ng-container>
