<header>
    <div class="container">
        <div class="header-display">
            <div class="logo-wrap">
                <img class="logo" src="/assets/img/misc/logo-imengine.svg" [alt]="'navigation_imengine_logo'|translate" />
                <!-- <span>poolcar registration</span> -->
            </div>
            <div class="profile-actions">
                <button placement="bottom-end" 
                [ngbPopover]="popContent">
                    <img class="profile-image" src="{{user.picture}}" [alt]="'prof pic ' + user.firstname + ' ' + user.lastname" /> 
                </button>
                <ng-template #popContent>
                    <div class="simple-popover">
                        <div>
                            {{user.firstname}}
                        </div>
                        <button (click)="logout()">
                            <div class="logout">
                                <i class="icon icon-logout"></i>
                                <span>{{'navigation_logout' | translate}}</span>
                            </div>
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>

    </div>
</header>