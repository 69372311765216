<div class="page">
    <div class="container">

        <div class="step--1" *ngIf="step === 1">
            <div class="distance-page">
                <div class="all-text">
                    <div class="end-reservations-text">
                        {{'addnewregistrations_title'|translate}}
                    </div>
                    <div class="distance-text">
                        {{'addnewregistrations_descr'|translate}}
                    </div>
                </div>
                <div class="km-distance" [class.error]="false">
                    <ng-container *ngFor="let index of [1,2,3,4,5,6]; index as $i">
                        <div [class.number-input--empty-error]="toLowKM&&kmDistance[5 - $i] === undefined" [class.number-input-error]="toLowKM&&kmDistance[5 - $i] !== undefined" class="number-input--empty" [class.number-input]="kmDistance[5 - $i] !== undefined" >
                            {{kmDistance[5 - $i] !== undefined ? kmDistance[5 - $i] : '_'}}
                        </div>
                        <div *ngIf="$i == 2">
                            <span>.</span>
                        </div>
                    </ng-container>
                </div>
                <div class="low-KM-wrap">
                    <ng-container *ngIf="toLowKM">
                        {{validation.km}}
                    </ng-container>
                </div>
                <div class="numpad-wrap">
                    <app-numpad (addNewNumberEvent)="addNumber($event)" (removeNumberEvent)="removeNumber()"></app-numpad>
                </div>
            </div>
            <div class="continue-button-wrap">
                <app-button [disabled]="goingNextStep" (clickButton)="nextStep()" buttonWidth="100%" [type]="'button--green'" [text]="'addnewregistrations_btn_submit' | translate" [imageLast]="true" [icon]="'arrow'"></app-button>
            </div>
            <!-- *ngIf <a !emitCancel.observers.length -->
            <a *ngIf="!emitCancel.observers.length" class="a-link" [routerLink]="['/my-registrations']">{{'addnewregistrations_btn_annulate' | translate}}</a>
            <button (click)="triggerEmitCancel()" class="button-link" *ngIf="emitCancel.observers.length">{{'addnewregistrations_btn_annulate' | translate}}</button>
            <!-- if emitCancel.observers.length -->
            <!-- button.button-link format-button -->
        </div>

        <div class="step--2" *ngIf="step === 2">
            <div class="distance-page">
                <div class="date-options-wrap" *ngIf="!showFullCalender">
                    <div class="date-title">
                        {{'completenewregistrations_date_title' | translate}}
                    </div>
                    <div class="specific-day-options">
                        <ng-container *ngFor="let date of showDateList; index as $i">
                            <div class="date-list" (click)="addDate($i)" [ngClass]="{'active-choice-option': chosenDates.indexOf(date) > - 1}" >
                                <div class="day">{{date | formatTs : 'dayShorthand'}}</div>
                                <div class="date">{{date | formatTs: 'D'}}</div>
                                <div class="month">{{date | formatTs: 'monthShorthand'}}</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="choose-other-date" (click)="switchShowFullCalender()">
                        {{'completenewregistrations_date_to_calender' | translate}}
                    </div>
                </div>
                <div class="calender-wrap" *ngIf="showFullCalender">
                    <app-date-picker (modelChange)="fillInDate($event)" [startDate]="FORM.startDate" [endDate]="FORM.endDate" [labelText]="'calender_label' | translate"></app-date-picker>
                </div>
                <div class="choice-wrap">
                    <app-switch [labelText]="'completenewregistrations_choice_title' | translate" [model]="FORM.reason" (modelChange)="switchPrivateWork($event)" [options]="options.reason"></app-switch>
                </div>
                <div *ngIf="FORM.reason === 'client'" class="customer-wrap">
                    <app-client-input [label]="'completenewregistrations_customer' | translate" [placeholder]="'completenewregistrations_customer_input' |translate" (changeName)="FORM.client = $event" [errorMessage]="validation.client" [clientName]="FORM.client" ></app-client-input>
                </div>
            </div>
            <div class="finish-button-wrap">
                <app-button [disabled]="submitting" *ngIf="!emitSubmit.observers.length" buttonWidth="100%" [type]="'button--green'" (clickButton)="submit()" [text]="'completenewregistrations_btn_continue' | translate" [imageLast]="true" [icon]="'arrow'"></app-button>
                <app-button [disabled]="submitting" *ngIf="emitSubmit.observers.length" buttonWidth="100%" [type]="'button--green'" (clickButton)="submit()" [text]="'completenewregistrations_btn_continue' | translate" [imageLast]="true" [icon]="'arrow'"></app-button>
            </div>
            <app-button (clickButton)="previousStep()" buttonWidth="100%" [type]="'button--light'" [text]="'completenewregistrations_btn_back' | translate" [imageFirst]="true" [icon]="'back-arrow'"></app-button>
        <div>
    </div>
</div>