import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { TranslatorService } from './translator.service';
// import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        private TranslatorService: TranslatorService,
        // private DefaultService: DefaultService,
        private AuthenticationService: AuthenticationService
    ) {}

    initialize() {
        return new Promise<void>((resolve, reject) => {
            this.AuthenticationService.fetchUser().subscribe((next) => {
                this.TranslatorService.init('nl').subscribe((next) => {
                    resolve();
                });
            });

            // this.DefaultService.configGetConfig().subscribe((next: any) => {
            //     this.ConfigService.setConfig({ node_env: next.node_env, instrumentationkey: next.instrumentationkey });
            //     this.MonitoringService.init();
            //     this.AuthenticationService.fetchUser().subscribe((next) => {
            //         const observables$: Observable<any>[] = [Observable.of(null)];
            //         observables$.push(...this.ResourceService.init());
            //         observables$.push(this.TranslatorService.init(next.language));
            //         forkJoin(observables$).subscribe((next) => {
            //             resolve();
            //         });
            //     });
            // });
        });
    }
}
