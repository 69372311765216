import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationsService } from 'src/app/services/registrations.service';
import { DefaultService } from 'src/app/utils/api';
import { ConfirmDeleteComponent } from '../../modals/confirm-delete/confirm-delete.component';

@Component({
    selector: 'app-registration-detail',
    templateUrl: './registration-detail.component.html',
    styleUrls: ['./registration-detail.component.scss']
})
export class RegistrationDetailComponent implements OnInit {
    activeRegistration: any = null;
    RPP: number = 25;
    startRow: number = 0;
    totalRows: number = undefined;
    deleteModal: NgbModalRef = null;
    deleteId: string = null;
    deleting: boolean = false;

    id: string = null;

    constructor(
        private defaultService: DefaultService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private registrationService: RegistrationsService
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params.id !== 'new') {
                this.id = params.id;
                this.init();
            }
        });
    }

    init() {
        this.defaultService.registrationGetAll(this.startRow, null, null, null, this.id).subscribe((next: any) => {
            this.activeRegistration = next.data[0];
        });
    }

    openConfirmDeleteModal(registrationId) {
        this.deleteId = registrationId;
        this.deleteModal = this.modalService.open(ConfirmDeleteComponent);
        this.deleteModal.componentInstance.deleting = this.deleting;
        this.deleteModal.componentInstance.confirmed.subscribe((next) => {
            this.submitDelete();
        });
        this.deleteModal.componentInstance.canceled.subscribe((next) => {
            this.deleteModal.dismiss();
        });
    }

    submitDelete() {
        this.deleting = true;
        setTimeout(() => {
            this.registrationService.deleteRegistrations(this.deleteModal, this.deleteId);
        }, 400);
    }
}
