import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-client-input',
    templateUrl: './client-input.component.html',
    styleUrls: ['./client-input.component.scss']
})
export class ClientInputComponent implements OnInit {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() clientName: string;
    @Input() errorMessage: string;
    @Output() changeName: EventEmitter<any> = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}

    changeNameKeyDown() {
        this.changeName.emit(this.clientName);
    }
}
