import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ngfModule, ngf } from 'angular-file';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModule, Configuration, ConfigurationParameters } from './utils/api';
import {
    displayPipe,
    objLenPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    ArrayPipe,
    callbackPipe,
    SafePipe,
    floatDisplayPipe,
    numberDisplayPipe,
    vatPipe,
    venPipe,
    FileSizePipe,
    thousandSeparatorPipe
} from './utils/pipes';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { InitService } from './services/init.service';
import { SortablejsModule } from 'ngx-sortablejs';
import { MyRegistrationsComponent } from './pages/my-registrations/my-registrations.component';
import { NavigationComponent } from './components/layout/navigation/navigation.component';
import { ButtonComponent } from './components/common/button/button.component';
import { NumpadComponent } from './components/misc/numpad/numpad.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { SwitchComponent } from './components/fields/switch/switch.component';
import { DatePickerComponent } from './components/fields/date-picker/date-picker.component';
import { ClientInputComponent } from './components/fields/client-input/client-input.component';
import { PaginationComponent } from './components/misc/pagination/pagination.component';
import { EmptyStateComponent } from './components/layout/empty-state/empty-state.component';
import { RegistrationDetailComponent } from './components/layout/registration-detail/registration-detail.component';
import { AddNewRegistrationComponent } from './components/forms/add-new-registration/add-new-registration.component';
import { ConfirmDeleteComponent } from './components/modals/confirm-delete/confirm-delete.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        formatTsPipe,
        displayPipe,
        objLenPipe,
        floatDisplayPipe,
        numberDisplayPipe,
        vatPipe,
        venPipe,
        thousandSeparatorPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        ArrayPipe,
        callbackPipe,
        FileSizePipe,
        SafePipe,
        MyRegistrationsComponent,
        NavigationComponent,
        ButtonComponent,
        AddNewRegistrationComponent,
        NumpadComponent,
        SpinnerComponent,
        SwitchComponent,
        DatePickerComponent,
        ClientInputComponent,
        PaginationComponent,
        EmptyStateComponent,
        RegistrationDetailComponent,
        ConfirmDeleteComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AngularMyDatePickerModule,
        ngfModule,
        QuillModule.forRoot(),
        SortablejsModule.forRoot({}),
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        NgxMaskModule.forRoot(options),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        // ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        // { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [InitService]
        },
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        objLenPipe,
        vatPipe,
        venPipe,
        formatTsPipe,
        thousandSeparatorPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
