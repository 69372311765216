import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddNewRegistrationComponent } from './components/forms/add-new-registration/add-new-registration.component';
import { EmptyStateComponent } from './components/layout/empty-state/empty-state.component';
import { RegistrationDetailComponent } from './components/layout/registration-detail/registration-detail.component';
import { MyRegistrationsComponent } from './pages/my-registrations/my-registrations.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
    { path: '', redirectTo: 'my-registrations', pathMatch: 'full' },
    // my-registrations
    {
        path: 'my-registrations',
        component: MyRegistrationsComponent,
        children: [
            {
                path: '',
                component: EmptyStateComponent,
                data: {
                    mobileFullWidth: false
                }
            },
            {
                path: 'new',
                component: AddNewRegistrationComponent,
                data: {
                    mobileFullWidth: true
                }
            },
            {
                path: ':id',
                component: RegistrationDetailComponent,
                data: {
                    mobileFullWidth: true
                },
                canActivate: [AuthGuardService]
            },
            {
                path: ':id/edit',
                component: AddNewRegistrationComponent,
                data: {
                    mobileFullWidth: true
                },
                canActivate: [AuthGuardService]
            }
        ]
    },
    //add-registration
    {
        path: 'add-registration',
        data: {},
        component: AddNewRegistrationComponent
    },
    //edit-registration
    {
        path: 'add-registration/:id',
        data: {},
        component: AddNewRegistrationComponent
    },
    // 404
    { path: '**', redirectTo: 'my-registrations' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
