<div class="numpad">
    <div class="button-wrap">
        <button (click)="addNumber(1)" class="numpad-button">1</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(2)" class="numpad-button">2</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(3)" class="numpad-button">3</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(4)" class="numpad-button">4</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(5)" class="numpad-button">5</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(6)" class="numpad-button">6</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(7)" class="numpad-button">7</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(8)" class="numpad-button">8</button>
    </div>
    <div class="button-wrap">
        <button (click)="addNumber(9)" class="numpad-button">9</button>
    </div>
    <div class="button-wrap">

    </div>
    <div class="button-wrap">
        <button (click)="addNumber(0)" class="numpad-button">0</button>
    </div>
    <div class="button-wrap-icon">
        <button (click)="removeLastNumber()"><i class="icon icon-delete-number"></i></button>
    </div>

    
</div>