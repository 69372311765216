import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelperService } from 'src/app/services/helper.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrationsService } from 'src/app/services/registrations.service';
import { DefaultService } from 'src/app/utils/api';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-add-new-registration',
    templateUrl: './add-new-registration.component.html',
    styleUrls: ['./add-new-registration.component.scss']
})
export class AddNewRegistrationComponent implements OnInit {
    kmDistance: string[] = [];
    chosenDates: string[] = [];
    toLowKM: boolean = false;
    submitting: boolean = false;
    goingNextStep: boolean = false;
    FORM: {
        reason: string;
        startDate: string;
        endDate: string;
        client: string;
        km: number;
        id?: string;
    } = {
        reason: 'private',
        startDate: '',
        endDate: '',
        client: '',
        km: 0
    };
    step: number = 1;
    isPrivate = true;
    clientFieldEmpty: boolean = false;
    validation: any = {
        kms: '',
        client: ''
    };
    showFullCalender: boolean = false;
    @Input() editId: string = undefined;
    @Output() emitCancel = new EventEmitter();
    @Output() emitSubmit = new EventEmitter();

    triggerEmitCancel() {
        this.emitCancel.emit();
    }

    options: any = {
        reason: [
            {
                title: this.translatePipe.transform('completenewregistrations_choice_private'),
                value: 'private'
            },
            {
                title: this.translatePipe.transform('completenewregistrations_choice_work'),
                value: 'client'
            }
        ]
    };
    showDateList: string[] = [];

    constructor(
        private router: Router,
        private defaultService: DefaultService,
        private translatePipe: TranslatePipe,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private helperService: HelperService,
        private registrationService: RegistrationsService
    ) {}

    ngOnInit(): void {
        let today = moment();
        for (let i = 0; i <= 5; i++) {
            // this.showDateList.unshift({
            //     day: today.format('ddd').replace('.', ''),
            //     dateDay: today.date(),
            //     month: today.format('MMM').replace('.', '')
            // });
            this.showDateList.unshift(today.format('YYYY-MM-DD'));
            today.subtract(1, 'days');
        }
        this.chosenDates.push(this.showDateList[5]);
        this.FORM.startDate = this.chosenDates[0];
        this.FORM.endDate = this.chosenDates.slice(-1)[0];

        this.route.params.subscribe((params) => {
            if (this.route.snapshot.params['id'] !== undefined || this.editId !== undefined) {
                if (this.route.snapshot.params['id'] !== undefined) {
                    this.editId = this.route.snapshot.params['id'];
                }
                this.defaultService.registrationGetAll(0, null, null, null, this.editId).subscribe((res) => {
                    this.FORM.client = res.data[0].client;
                    this.FORM.startDate = res.data[0].startDate;
                    this.FORM.km = res.data[0].km;
                    this.FORM.reason = res.data[0].reason;
                    this.FORM.endDate = res.data[0].endDate;
                    this.FORM.id = this.editId;
                    this.kmDistance = Array.from(String(this.FORM.km)).reverse();
                    this.showFullCalender = true;
                });
            }
        });

        // this.FORM.startDate = `${moment().year()}-${moment(this.chosenDates[0].month, 'MMM').format('M')}-${
        //     this.chosenDates[0].dateDay
        // }`;
        // this.FORM.endDate = `${moment().year()}-${moment(this.chosenDates.slice(-1)[0].month, 'MMM').format('M')}-${
        //     this.chosenDates.slice(-1)[0].dateDay
        // }`;
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.kmDistance.length < 6) {
            if (this.helperService.isNumeric(event.key)) {
                this.kmDistance.unshift(event.key);
            }
        }

        if (this.kmDistance.length != 0) {
            if (event.key == 'Backspace') {
                this.kmDistance.shift();
            }
        }
    }
    // resourceService.getOptionsForWhatever(next => {
    //     this.options.whatever = next.map(next => {
    //         return {
    //             ...next,
    //             title: <titleprop>,
    //             value: <valueprop>,
    //         }
    //     })
    // })
    switchShowFullCalender() {
        setTimeout(() => {
            this.showFullCalender = true;
        }, 1);
    }

    fillInDate($event) {
        this.FORM.startDate = $event.start_date;
        this.FORM.endDate = $event.end_date;
    }

    addDate(index: any) {
        if (this.chosenDates.length > 1) {
            this.chosenDates = [this.showDateList[index]];
        } else {
            // exact 1 datum geselecteerd
            var active_index = this.showDateList.indexOf(this.chosenDates[0]);
            this.chosenDates = [];
            if (index > active_index) {
                // als actieve_index hoger dan index van actieve dag .... loop
                for (let i = active_index; i <= index; i++) {
                    this.chosenDates.push(this.showDateList[i]);
                }
            } else {
                // omgekeerd ... lager
                for (let i = active_index; i >= index; i--) {
                    this.chosenDates.unshift(this.showDateList[i]);
                }
            }
        }

        this.FORM.startDate = this.chosenDates[0];
        this.FORM.endDate = this.chosenDates.slice(-1)[0];
    }

    nextStep() {
        this.goingNextStep = true;
        let currentKMStringReversed = this.kmDistance.join('');
        let currentKMString = currentKMStringReversed.split('').reverse().join('');
        let currentKMNumber = Number(currentKMString);
        setTimeout(() => {
            if (this.editId === undefined) {
                this.defaultService.registrationCheckKm(currentKMNumber).subscribe(
                    (next) => {
                        if (!next.error) {
                            this.FORM.km = currentKMNumber;
                            this.step = this.step + 1;
                        } else {
                            this.toLowKM = true;
                            this.validation.km = next.error;
                            this.goingNextStep = false;
                        }
                        // this.validation = {};
                    },
                    (error) => {
                        this.goingNextStep = false;
                        // code 400 = validation error (invalid payload)
                        // this.validation = error.error.details
                    }
                );
            } else {
                this.step = this.step + 1;
                this.FORM.km = currentKMNumber;
            }
        }, 400);
    }
    previousStep() {
        this.step = this.step - 1;
        this.goingNextStep = false;
    }

    addNumber($event) {
        if (this.kmDistance.length < 6) {
            this.kmDistance.unshift($event);
            this.toLowKM = false;
        }
    }
    removeNumber() {
        if (this.kmDistance.length != 0) {
            this.kmDistance.shift();
            this.toLowKM = false;
        }
    }

    redirectBack() {
        this.router.navigate(['my-registrations']);
    }

    switchPrivateWork($event) {
        this.FORM.reason = $event;
        this.FORM.client = '';
        this.clientFieldEmpty = false;
    }

    updateOrInsertRegistrations(messageText: string) {
        this.registrationService.updateRegistrations(this.FORM).subscribe(
            (next) => {
                this.router.navigate(['my-registrations'], { queryParams: { action: 'update' } });
                this.notificationService.sendMessage(this.translatePipe.transform(messageText), 'success');
            },
            (error) => {
                console.log(error.error);
                this.validation = error.error.details;
                this.submitting = false;
            }
        );
    }

    submit() {
        this.submitting = true;
        setTimeout(() => {
            if (this.editId === undefined) {
                if (this.FORM.client == '' && this.FORM.reason == 'client') {
                    this.clientFieldEmpty = true;
                }
                this.updateOrInsertRegistrations('registration_created_toastr');
            } else {
                this.updateOrInsertRegistrations('registration_edited_toastr');
            }
        }, 400);

        // if (this.FORM.start_date !== '' && this.FORM.end_date !== '') {
        //     if ((this.FORM.reason = 'private')) {
        //         // add registration
        //         //this.router.navigate(['my-registrations']);
        //     } else {
        //         if (this.FORM.client !== '') {
        //             // add registration
        //             //this.router.navigate(['my-registrations']);
        //         }
        //     }
        // }

        // this.router.navigate(['my-registrations']);
    }
}
