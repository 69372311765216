import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-numpad',
    templateUrl: './numpad.component.html',
    styleUrls: ['./numpad.component.scss']
})
export class NumpadComponent implements OnInit {
    @Output() addNewNumberEvent = new EventEmitter<number>();
    @Output() removeNumberEvent = new EventEmitter();

    constructor() {}

    addNumber(addNewNumber: number) {
        this.addNewNumberEvent.emit(addNewNumber);
    }
    removeLastNumber() {
        this.removeNumberEvent.emit();
    }
    ngOnInit(): void {}
}
