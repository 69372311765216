<ng-container *ngIf="!link">
    <button [class.disabled]="disabled" [ngClass]="[type]" [style.width]="buttonWidth" (click)="clicked()" class="app-button">
        <i *ngIf="imageFirst" class="icon icon-{{icon}} icon--before"></i>
        <span>{{text}}</span>
        <i *ngIf="imageLast" class="icon icon-{{icon}} icon--after"></i>
    </button>
</ng-container>

<ng-container *ngIf="link">
    <a [class.disabled]="disabled" [ngClass]="[type]" [style.width]="buttonWidth" [routerLink]="link" class="app-button">
        <i *ngIf="imageFirst" class="icon icon-{{icon}} icon--before"></i>
        <span>{{text}}</span>
        <i *ngIf="imageLast" class="icon icon-{{icon}} icon--after"></i>
    </a>
</ng-container>
