import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
    @Output() modelChange: EventEmitter<any> = new EventEmitter();
    @Input() options: any;
    @Input() model: any;
    isPrivate = true;
    @Input() labelText: string;

    constructor() {}

    ngOnInit(): void {}

    selectOption(opt) {
        this.modelChange.emit(opt.value);
    }
}
