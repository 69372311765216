import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ConfirmDeleteComponent } from 'src/app/components/modals/confirm-delete/confirm-delete.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RegistrationsService } from 'src/app/services/registrations.service';

@Component({
    selector: 'app-my-registrations',
    templateUrl: './my-registrations.component.html',
    styleUrls: ['./my-registrations.component.scss']
})
export class MyRegistrationsComponent implements OnInit {
    registrations: any[] = [];
    ready: boolean = false;
    user: any;
    RPP: number = 25;
    startRow: number = 0;
    totalRows: number = undefined;
    currentPage: number = 1;
    isAdmin: boolean = false;
    showEditView: boolean = false;
    deleting: boolean = false;
    deleteId: string = null;

    deleteModal: NgbModalRef = null;

    activeId: string = '';
    saving: boolean = false;

    mobileFullWidth: boolean = false;

    constructor(
        private registrationService: RegistrationsService,
        private authService: AuthenticationService,
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    private getRegistrations() {
        this.registrationService.getRegistrations(this.startRow, this.RPP, ['startDate desc']).subscribe((next) => {
            if (this.route.snapshot.queryParams['page'] && next.data.length === 0) {
                this.router.navigate(['my-registrations'], { queryParams: { action: 'update' } });
                this.startRow = 0;
            } else {
                this.registrations = next.data;
                this.totalRows = next.rows;
                this.ready = true;
            }
        });
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParams['page'] !== undefined) {
            this.currentPage = this.route.snapshot.queryParams['page'];
        }
        this.user = this.authService.getUserValue();
        if (this.user.roles.includes('admin')) {
            this.isAdmin = this.user.roles.includes('admin');
            const activeId = this.route.firstChild.snapshot.params['id'];
            if (activeId) {
                this.activeId = activeId;
            }
        }

        // this.TranslatePipe.transform('myregistrations_....');
        this.startRow = (this.currentPage - 1) * this.RPP;

        this.getRegistrations();

        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                this.checkMobileFullWidth();
            }
        });
        this.route.queryParams.subscribe((params) => {
            if (params.action == 'update') {
                this.getRegistrations();
                this.router.navigate([], {
                    queryParams: {
                        action: null
                    }
                });
            }
        });
        this.checkMobileFullWidth();
    }

    checkMobileFullWidth() {
        this.mobileFullWidth = this.route.snapshot.firstChild.data.mobileFullWidth;
    }

    editIsCompleteOrCanceled() {
        this.getRegistrations();
        this.showEditView = false;
    }

    openConfirmDeleteModal(registrationId) {
        this.deleting = false;
        this.deleteId = registrationId;
        this.deleteModal = this.modalService.open(ConfirmDeleteComponent);
        this.deleteModal.componentInstance.deleting = this.deleting;
        this.deleteModal.componentInstance.confirmed.subscribe((next) => {
            this.submitDelete();
        });
        this.deleteModal.componentInstance.canceled.subscribe((next) => {
            this.deleteModal.dismiss();
        });
    }

    submitDelete() {
        this.deleting = true;
        setTimeout(() => {
            this.registrationService.deleteRegistrations(this.deleteModal, this.deleteId);
        }, 400);
    }

    startRowChange($event) {
        this.saving = true;
        let temp = $event / this.RPP + 1;
        this.router.navigate([`my-registrations`], { queryParams: { page: temp } });

        this.startRow = $event;
        this.getRegistrations();

        setTimeout(() => {
            this.saving = false;
        }, 500);
    }
}
