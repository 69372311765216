import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private notificationSubject: Subject<string> = new Subject<string>();
    private message: string;
    private type: string;

    sendMessage(message: string, type: string) {
        this.message = message;
        this.type = type;
        this.notificationSubject.next(type);
    }
    constructor(private toastr: ToastrService) {
        this.notificationSubject.subscribe(
            (type) => {
                switch (type) {
                    case 'success':
                        this.toastr.success(this.message);
                        break;
                    case 'error':
                        this.toastr.error(this.message);
                        break;

                    case 'warning':
                        this.toastr.warning(this.message);
                        break;

                    case 'info':
                        this.toastr.info(this.message);
                        break;

                    default:
                        this.toastr.info(this.message);
                        break;
                }
            },
            (err) => console.log(err)
        );
    }
}
