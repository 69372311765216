import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-delete',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
    @Input() deleting: boolean = false;
    @Output() confirmed: EventEmitter<any> = new EventEmitter();
    @Output() canceled: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
