import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() imageFirst: boolean;
    @Input() imageLast: boolean;
    @Input() text: string;
    @Input() icon: string;
    @Input() type: string;
    @Input() link: any[];
    @Input() disabled: boolean = false;
    @Output() clickButton: EventEmitter<any> = new EventEmitter();
    @Input() buttonWidth: string = '';

    constructor() {}

    ngOnInit(): void {}

    clicked() {
        this.clickButton.emit();
    }
}
